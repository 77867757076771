<template>
  <PublicApply :post="post"></PublicApply>
</template>
<script>
import PublicApply from './PublicApply'
export default {
  name: 'MediateHandle',
  data () {
    return {
      post: {
        service_type: '预约-调解服务',
        type: '人民调解机构',
        service_name: '人民调解办理',
        info: '' +
          '<p>1.申请人保证以上本人填写的情况及提供的资料真实有效；<p>' +
          '<p>2.申请人应如实陈述案件事实及相关情况，及时全面提供有关证明和证据材料；</p>' +
          '<p>3.申请人应协助调查调解相关事项的事实；</p>' +
          '<p>4.申请人应当遵守《人民调解法》第24条规定，履行下列义务：(一)如实陈述纠纷事实；(二)遵守调解现场秩序，尊重人民调解员；(三)尊重对方当事人行使权利。</p>' +
          '<p>5.申请人应当遵守《人民调解法》第23条规定，享有下列权利：(一)选择或者接受人民调解员；(二)接受调解、拒绝调解或者要求终止调解；(三)要求调解公开进行或者不公开进行；(四)自主表达意愿、自愿达成调解协议。</p>'
      }
    }
  },
  components: {
    PublicApply
  }
}
</script>
<style scoped >
</style>
